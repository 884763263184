@use "./palette" as c;
@use "./variables" as v;

.medium-view{
    .pricefeed-cards-update{
        .ratio-container__right{
            flex-direction: column;
            >div{
                flex: 1;
            }
            .market-card-root{
                width: 100%;
                max-width: 100%;
                height: 100%;
                background-size: cover;
                .button-v2-wrapper{
                    margin-bottom: 20px;
                }
            }
        }
    }
}
.small-view{
    .pricefeed-cards-update{
        .g-container{
            margin: 0;
        }
    }
}

@media all and (max-width:900px){
    .medium-view{
        .pricefeed-cards-update{
            .ratio-container__right{
                flex-direction: row;
                .market-card-root{
                    min-height: 206px;
                    max-width: 100%;
                }
            }
        }
    }
}

.small-view{
    .pricefeed-cards-update{
        .price-feed-new{
            .instrument-slider--new{
                margin-right: -20px;
            }
        }
        .market-card-root{
            max-width: 100%;
        }
        .ratio-container__right{
            padding: 20px;
        }
    }
}